import React, { useEffect, useState } from "react";
import ChecklistItem from "./checklistitem";
import '../styles/checklist.css';
import { Container,Row,Col, Form } from "react-bootstrap";
import Menu from "./menu";
import MyButton from "./mybutton";
import MyProgressBar from "./myprogressbar";
import { useParams } from "react-router-dom";
import AuthContext from '../context/authprovider';
import { useContext } from "react";
export default function OnboardingList(props){
    const params=useParams();
    const [grantee,setGrantee]=useState({});
    const [fArray,setFArray] = useState([]);
    const [vArray,setVArray] = useState([]);
    const [pArray,setPArray] = useState({one:0,two:0,three:0});
    const [complete,setComplete] = useState({one:false,two:false,three:false});
    ///PULL PHA ID FROM CONTEXT
    const {auth}=useContext(AuthContext);
    const [phaid,setPhaid]=useState(auth.userid);


    const phaseClick=(phase)=>{
        //filter full array and load it into varray remember to use ...
        let tArray=[];
        fArray.forEach((task)=>{
            if(task.phase==phase){
                tArray.push(task)
            }
        })
        setVArray(tArray)
    }
    const markComplete=(phase)=>{
        let x = !complete[phase];
        let aphase=0;
        switch(phase){
            case "one":
                aphase=1;
                break;
            case "two":
                aphase=2;
                break;
            case "three":
                aphase=3;
                break;
        }
        if(x==true){
            fetch(`https://www.api.tctcollab.com/pmonboarding/complete/phase/${aphase}/user/${params.id}/pha/${phaid}`)
            .then(results=>results.json())
            .then(data=>{})
        }
        else{
            fetch(`https://www.api.tctcollab.com/pmonboarding/incomplete/phase/${aphase}/user/${params.id}`)
        }
        setComplete(complete=>({...complete,[phase]:x}))
        // mark everything complete in this phase using fetch
    }
    useEffect(()=>{
        //fetch params.id and then use it to load grantee info into grantee
        //use params.id to pull list of all tasks and load it into farray and mark things complete
        //do calculations on phases and load it into parray
        fetch(`https://www.api.tctcollab.com/user/${params.id}`)
        .then((results)=>results.json())
        .then((data)=>{setGrantee(data[0])})
        fetch(`https://www.api.tctcollab.com/pmonboarding/list/user/${params.id}`)
        .then((results)=>{return(results.json())})
        .then((data)=>{
            setFArray(data.tasks);
            setVArray(data.tasks);
            // console.log(data)
            setPArray({one:data.phase[0],two:data.phase[1],three:data.phase[2]});
            if(data.phase[0]==100){
                setComplete({...complete,one:true})
            }
            if(data.phase[1]==100){
                setComplete({...complete,two:true})
            }
            if(data.phase[2]==100){
                setComplete({...complete,three:true})
            }
        })
    },[])
    return(
        <Container>
            <Row>
                <Col>
                <div className="onboarding-list-header-container"></div>
                </Col>
            </Row>
            <Row>
                <Col lg="3">
                    <Menu>
                        <MyButton variant="green" onClick={()=>phaseClick("1")}>
                            Phase 1
                        </MyButton>
                        <MyButton variant="purple"  onClick={()=>phaseClick("2")}>
                            Phase 2
                        </MyButton>
                        <MyButton variant="orange"  onClick={()=>phaseClick("3")}>
                            Phase 3
                        </MyButton>
                    </Menu>
                </Col>
                <Col lg="9">
                    <Menu>
                        <div className="name">
                            {grantee!={}&&grantee.fname+" "+grantee.lname}
                        </div>
                        <div className="org">
                            {grantee!={}&&grantee.descr}
                        </div>
                        
                        <div className="phase-completion-container">
                            <div className="bar-container">
                                <div className="phase-progress">Phase 1</div>
                                <MyProgressBar percentage={pArray.one}/>
                                <div className="checkbox-container">
                                    <Form.Check 
                                        type="switch"
                                        label="Mark Phase 1 Complete"
                                        checked={complete.one}
                                        onClick={()=>markComplete("one")}
                                    />
                                </div>
                            </div>
                            <div className="bar-container">
                                <div className="phase-progress">Phase 2</div>
                                <MyProgressBar percentage={pArray.two}/>
                                <div className="checkbox-container">
                                    <Form.Check 
                                        type="switch"
                                        label="Mark Phase 2 Complete"
                                        checked={complete.two}
                                        onClick={()=>markComplete("two")}
                                    />
                                </div>
                            </div>
                            <div className="bar-container">
                                <div className="phase-progress">Phase 3</div>
                                <MyProgressBar percentage={pArray.three}/>
                                <div className="checkbox-container">
                                    <Form.Check 
                                        type="switch"
                                        label="Mark Phase 3 Complete"
                                        checked={complete.three}
                                        onClick={()=>markComplete("three")}
                                    />
                                </div>
                            </div>
                        </div>
                    </Menu>               
                    <div className="onboarding-list">                    
                        <div className="phase-container">
                            {vArray.map((task)=>{
                                // console.log(task);
                                return(<ChecklistItem key={task.ataskid} userid={params.id} phaid={phaid} taskid={task.ataskid} phase={task.phase} title={task.task_name} resourceid={task.resourceid} resourcelink={task.resource_url} resourcename={task.resource_name} complete={task.completion_date!=null?true:false}>{task.task_descr}</ChecklistItem>)
                            })}
                        </div>
                    </div>
                </Col> 
            </Row>
        </Container>
    )
}