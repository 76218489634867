import React, { useEffect, useState } from "react";
import '../styles/pmo.css';
import {useNavigate, Link} from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Avatar from '../img/avatar.png';
export default function OnboardingBox(props){
    const [barColor,setBarColor]=useState("");
    const [bgColor,setBgColor]=useState("");
    const [barPercentage,setBarPercentage]=useState(0);
    const [pColor,setPColor]=useState("black");
    const [onboarded,setOnboarded]=useState(true);
    const [textColor,setTextColor]=useState("black");
    const boxStyle={
        width:"300px",
        height:"350px",
        
        border:"1px solid black",
        boxSizing:"border-box",
        boxShadow:"2px 2px 5px grey",
        transition:"background-color .5s",
        margin:"10px",
        position:"relative",
        overflow:"hidden"

    }
    const blurStyle={
        backgroundColor:"rgba(200,200,200,.8)",
        
        width:"100%",
        height:"100%",        
        display:"flex",
        flexFlow:"column",
        justifyContent:"spaceBetween",
        boxSizing:"border-box"
    }
    const contentContainerStyle={
        width:"290px",
        height:"340px",        
        // display:"flex",
        flexFlow:"column",
        justifyContent:"spaceBetween",
        background:{Avatar},
        position:"absolute",
        top:"5px",
        left:"5px",
        zIndex:"4",
        backgroundColor:bgColor,
        
        boxSizing:"border-box"
    }
    const contentContainerBGStyle={
        width:"290px",
        height:"340px",        
        display:"flex",
        flexFlow:"column",
        // justifyContent:"spaceBetween",
        background:`url(${Avatar}) center no-repeat`,
        backgroundSize:"90%",
        position:"absolute",
        // top:"5px",
        // left:"5px",
        // zIndex:"4",
        // backgroundColor:bgColor,
        
        // boxSizing:"border-box"
    }
    const gNameStyle={
        fontWeight:"bold",
        color:textColor,
        width:"100%",
        paddingTop:"10px",
        fontSize:"30px",
        zIndex:"200"
    }
    const gTextStyle={
        fontWeight:"bold",
        color:textColor,
        width:"100%",
        paddingBottom:"10px"
    }
    const gToggleStyle={
        fontWeight:"bold",
        color:textColor,
        paddingBottom:"10px",
        margin:"auto",
        display:"flex",
        width:"100%",
        justifyContent:"center"
    }
    const barContainerStyle={
        height:"30px",
        backgroundColor:"rgb(250,250,250)",
        width:"80%",
        borderRadius:"15px",
        overflow:"hidden",
        boxShadow:"inset 0 0 5px grey"
    }
    const barStyle={
        paddingTop:"3px",
        paddingBottom:"3px",
        minHeight:"100%",
        width:barPercentage+"%",
        backgroundColor:barColor,
        boxSizing:"border-box",
        borderRadius:"0 15px 15px 0",
        boxShadow:"inset 0 0 3px grey",
        transition:"width .5s"
    }
    const percentStyle={
        fontWeight:"bold",
        color:pColor
    }

    const changeToggle=()=>{
        if(onboarded===true){
            fetch(`https://www.api.tctcollab.com/pmonboarding/toggle/${0}/pha/${props.phaid}/user/${props.id}`)
        }
        else{
            fetch(`https://www.api.tctcollab.com/pmonboarding/toggle/${1}/pha/${props.phaid}/user/${props.id}`)
        }
        setOnboarded(!onboarded);
    }
    // const openOnboarding=()=>{
    //     let navigate= useNavigate()
    //     navigate(`${props.id}`)
    // }
    useEffect(()=>{
        if(props.onboarding==null || props.onboarding==1){
            setOnboarded(true)
        }
        else{
            setOnboarded(false)
        }
        setTimeout(()=>{setBarPercentage(props.percentage)},500)
        props.percentage<20 ? setBarColor("red") : props.percentage<80 ? setBarColor("yellow"): setBarColor("green");
        props.percentage<20 ? setBgColor("red") : props.percentage<80 ? setBgColor("yellow"): setBgColor("green");
        props.percentage>=80 ? setPColor("white"):setPColor("black");
    },[])
    useEffect(()=>{
        setBarPercentage(props.percentage)
        props.percentage<20 ? setBarColor("red") : props.percentage<80 ? setBarColor("yellow"): setBarColor("green");
        props.percentage<20 ? setBgColor("red") : props.percentage<80 ? setBgColor("yellow"): setBgColor("green");
        props.percentage>=80 ? setPColor("white"):setPColor("black");
    },[props.percentage])
    return(
        <div className="onboarding-box" style={boxStyle}>
            {props.new ? <div className="new"></div> :null}
            <div className="border"></div>
            <div className="content-container" style={contentContainerStyle}>
            <div style={contentContainerBGStyle}>
                <div className="blurLayer" style={blurStyle}>                
                    <div>
                        <div className="grantee-name" style={gNameStyle}>
                            {props.name}
                        </div>
                        <div className="title" style={gTextStyle}>
                            Program Manager
                        </div>
                        <div className="toggle-switch" style={gToggleStyle}>
                            <Form.Check 
                                type="switch"
                                label="Onboard Process"
                                checked={onboarded}
                                onClick={changeToggle}
                            />
                        </div>
                    </div>
                    <Link to={`${props.id}`} className="bar-container m-auto" style={barContainerStyle}>

                        <div className="bar" style={barStyle}>
                            <div className="percent" style={percentStyle}>{barPercentage+"%"}</div>
                        </div>
                    </Link>
                    <div className="grantee-name" style={gTextStyle}>
                        {props.org}
                    </div>              
                </div>
                </div>
            </div>
        </div>
    )

}