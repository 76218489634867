import { useEffect,useState } from "react";
import {Link} from "react-router-dom";
import Webinar from "../img/webinars2.jpg";
import Training from "../img/training.jpg";
import Bp from "../img/bestpractices.jpg";
import Reference from "../img/refmaterials.jpg";
import MyButton from "./mybutton";
import Sgr from "../img/sgr.jpg";
import Ppt from "../img/ppti.jpeg";
export default function BoxList (props){
    const [active,setActive]=useState(false);
    const [description,setDescription] = useState(false);
    // const [titleStyle,setTitleStyle]=useState({});
    const [color,setColor] = useState("");
    const [bgColor,setBgColor] =useState("");
    const [bgImage,setBgImage] =useState();
    const [display,setDisplay] =useState("");
    const [isShown,setIsShown] =useState(true);
    const clickHandler=()=>{
        setActive(!active);
        // console.log(active);
        if(!active){
            setDescription(true)
        }
        else(
            setDescription(false)
        )
    }

    const titleStyleContainer = {
        color:color,
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        backgroundColor:bgColor, 
        minHeight:"68px",
    }
    const titleStyle= {
        fontSize:"16px",
        padding:"10px"
    }
    const boxStyleContainer = {
        display:display, 
        // backgroundSize:"cover",
        backgroundImage:`url(${bgImage})`,
    }
    const descriptionStyle={
        overflowY:"auto",
        background:"white",
        // backgroundImage:`url(${bgImage})`
        // height:"100%"
    }
    useEffect(()=>{
        !isShown && setDisplay("none")
        switch(props.type){
            case "Webinar":{
                setBgColor("rgb(255,150,34)");
                setColor("black");
                setBgImage(Webinar);
                break;
            }
            case "Online Course":{
                setBgColor("rgb(57,127,12)");
                setColor("white");
                setBgImage(Training);
                break;
            }
            case "Best Practice":{
                setBgColor("rgb(139,20,213)");
                setColor("white");
                setBgImage(Bp);
                // setTitleStyle({backgroundColor:bgColor,color:color})
                break;
            }
            case "Surgeon General Report":{
                setBgColor("rgb(255,150,34)");
                setColor("black");
                setBgImage(Sgr);
                break;
            }
            case "Reference Material":{
                setBgColor("#0ADAFF");
                setColor("black");
                setBgImage(Reference);
                // setTitleStyle({backgroundColor:bgColor,color:color})
                break;
            }
            case "Powerpoint":{
                setBgColor("rgb(184, 54, 15)");
                setColor("white");
                setBgImage(Ppt);
                break;
            }
            default:{
                setBgColor("");
                setColor("black");
                // setTitleStyle({backgroundColor:bgColor,color:color})
            }
        }
        

    },[])
    return(
        <div className={active?"boxlist active":"boxlist"} onClick={clickHandler} >
            
           <div className="boxlist-title" style={titleStyleContainer}>
            {props.type =="NTCP"?
                <Link to={`${props.id}`} style={{color:color,textDecoration:"none"}} ><h4>{props.title}</h4></Link> :
                <h4 style={titleStyle} onClick={()=>window.open(`${props.url}`, '_blank')}>{props.title}</h4>
            }
            {props.description!==""&&<MyButton variant="grey" style={{marginRight:"10px"}} onClick={()=>setActive(!active)}>More Info</MyButton>}
            </div> 
            {active?
            <div className="boxlist-descr-container" style={boxStyleContainer}>
            {description? <div className="boxlist-description" style={descriptionStyle} dangerouslySetInnerHTML={{__html:props.description}}>
                {/* {props.description} */}
                </div>:null}
            </div>
            :null}
        </div>
    );

}