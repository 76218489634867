import React from "react";
import {
    Col,
    Container,
    Row,
    Table,
    Button,
    InputGroup,
    FormControl,
      Image,
    Form
  } from "react-bootstrap";
import { useEffect, useState } from "react";
import Menu from "./menu";
import Box from "./box";
import BoxList from "./boxlist";
import { type } from "@testing-library/user-event/dist/type";
import Steps from './steps';

export default function TCE(){
    const [menu,setMenu]=useState(false);
    const [typeFilter,setTypeFilter]=useState([]);
    const [mainFilter,setMainFilter] = useState([]);
    const [compFilter,setCompFilter] = useState([]);
    const [fullArray, setFullArray] = useState([]);
    const [mainMenu,setMainMenu]=useState([]);
    const [compMenu,setCompMenu]=useState([]);
    const [typeMenu,setTypeMenu]=useState([]);
    const [search,setSearch]=useState("");
    const [listView,setListView]=useState(false);

    const handleClick=(option)=>{
        if(option=="Instructions"){
            setMenu(false);
        }
        else{
            setMenu(true);
            mainFilterFunction(option);
        }
    }
    const typeFilterFunction=(fil)=>{
        if(typeFilter.includes(fil)){
            let filArray=typeFilter;
            let ind=filArray.indexOf(fil);
            filArray.splice(ind,1);
            setTypeFilter([...filArray]);
        }else{
            let filArray = typeFilter;
            filArray.push(fil);
            setTypeFilter([...filArray]);
            // console.log(filter);
        }
    }

    const mainFilterFunction=(fil)=>{
        if(fil=="All"){
            setMainFilter([])
        }
        else if(mainFilter.includes(fil)){

        }else{
            // let filArray = mainFilter;
            // filArray.push(fil);
            // setMainFilter([...filArray]);
            // console.log(filter);
            setMainFilter([fil])
        }
    }

    const compFilterFunction=(fil)=>{
        if(compFilter.includes(fil)){
            let filArray=compFilter;
            let ind=filArray.indexOf(fil);
            filArray.splice(ind,1);
            setCompFilter([...filArray]);
        }else{
            let filArray = compFilter;
            filArray.push(fil);
            setCompFilter([...filArray]);
          // console.log(filter);
        }
    }

    const searchFunction=(event)=>{
        setSearch(event.target.value.toLowerCase());
        // console.log(search)
    }

    useEffect(()=>{
        fetch("https://www.api.tctcollab.com/resources")
        .then((results)=>results.json())
        .then((data)=>{
            // console.log(data);
            setFullArray(data);
        })
        fetch("https://www.api.tctcollab.com/resources/competencies")
        .then((results)=>results.json())
        .then((data)=>{
            // console.log(data);
            setCompMenu(data);
        })
        fetch("https://www.api.tctcollab.com/resources/types")
        .then((results)=>results.json())
        .then((data)=>{
            // console.log(data);
            setTypeMenu(data);
        })
        fetch("https://www.api.tctcollab.com/resources/sections")
        .then((results)=>results.json())
        .then((data)=>{
            // console.log(data);
            setMainMenu(data);
        })
    },[])
    return(
        <Container>
			<Row>
				<Col xl="3">							
                    <Menu width="100%">
                        <h2>Im looking for:</h2>
                        <Button variant="info" style={{width:"100%"}} onClick={()=>handleClick("Instructions")}>Page Instructions</Button>
                        <Button variant="success" style={{width:"100%"}} onClick={()=>handleClick("All")}>All</Button>
                        {
                            mainMenu.map((section)=>{
                                return(
                                    <Button variant="primary" style={{width:"100%"}} onClick={()=>handleClick(section.descr)}>{section.descr}</Button>
                                )
                            })
                        }
                        {/* <Button variant="primary" style={{width:"100%"}} onClick={()=>handleClick("Toolkit")}>ToolKit</Button> */}
                    </Menu>										
                </Col>
				<Col xl="9">
					{menu && <Menu width="100%" animate={true} time=".8s">
                        <div><Form.Check 
                                type="switch"
                                label="List View"
                                checked={listView}
                                onClick={()=>setListView(!listView)}
                            /></div>
                        <div style={{padding:"10px"}}>
                            <Form.Control style={{width:"100%"}}type="text" label="Search" onChange={searchFunction} value={search} placeholder="Search here" />
                        </div>
                        <div className="check-container" style={{display:"flex",width:"100%", flexWrap:"wrap"}}>
                            
                            {
                                typeMenu.map((type)=>{
                                    return(
                                        <div style={{padding:"10px"}}>
                                            <Form.Check
                                            label={type.descr}
                                            onChange={()=>typeFilterFunction(type.descr)}
                                            />
                                        </div>
                                    )
                                })
                            }
                            {/* <div style={{padding:"10px"}}>
                                <Form.Check
                                label="NTCP Webinar"
                                onChange={()=>typeFilterFunction("NTCP")}
                                />
                            </div>
                            <div style={{padding:"10px"}}>
                                <Form.Check
                                label="TCTC Webinar"
                                onChange={()=>typeFilterFunction("TCTC Webinar")}
                                />
                            </div>
                            <div style={{padding:"10px"}}>
                                <Form.Check
                                label="Training"
                                onChange={()=>typeFilterFunction("Training")}
                                />
                            </div> */}
                        </div>
                        <hr/>
                        <div className="check-container" style={{display:"flex",width:"100%", flexWrap:"wrap"}}>
                            {
                                compMenu.map((comp)=>{
                                    return(
                                        <div style={{padding:"10px"}}>
                                            <Form.Check
                                            label={comp.descr}
                                            onChange={()=>compFilterFunction(comp.descr)}
                                            />
                                        </div>
                                    )
                                })
                            }
                            
                            {/* <div style={{padding:"10px"}}>
                                <Form.Check
                                label="Policy"
                                onChange={()=>compFilterFunction("Policy")}
                                />
                            </div> */}
                        </div>
                    </Menu>}
                    <div className="box-container">
                    
                    {menu?
                        listView ?
                            fullArray.map((resource)=>{
                                return(
                                    ((resource.resource_name.toLowerCase().includes(search) || resource.descr.toLowerCase().includes(search) || search.length==0) && (typeFilter.includes(resource.type_descr) || typeFilter.length==0) && (mainFilter.some(r=> resource.sections.indexOf(r) >= 0) || mainFilter.length==0) && (compFilter.some(r=>resource.competencies.indexOf(r) >= 0) || compFilter.length==0)) && <BoxList title={resource.resource_name} type={resource.type_descr} section={resource.sections} competency={resource.competencies} url={resource.resource_url}id={resource.resourceid} description={resource.descr} key={resource.resourceid}/>
                                )
                            })
                            :
                            fullArray.map((resource)=>{
                                return(
                                    ((resource.resource_name.toLowerCase().includes(search) || resource.descr.toLowerCase().includes(search) || search.length==0) && (typeFilter.includes(resource.type_descr) || typeFilter.length==0) && (mainFilter.some(r=> resource.sections.indexOf(r) >= 0) || mainFilter.length==0) && (compFilter.some(r=>resource.competencies.indexOf(r) >= 0) || compFilter.length==0)) && <Box title={resource.resource_name} type={resource.type_descr} section={resource.sections} competency={resource.competencies} url={resource.resource_url}id={resource.resourceid} description={resource.descr} key={resource.resourceid}/>
                                )
                            })                       
                        :<Steps />
                    }
                        {/* {((typeFilter.includes('NTCP') || typeFilter.length==0) && (mainFilter.some(r=> ["Toolkit","Key Resources"].indexOf(r) >= 0) || mainFilter.length==0) && (compFilter.some(r=>["Cessation","Regulation"].indexOf(r) >= 0) || compFilter.length==0)) && <Box title="NTCP Webinar June 2022" type="NTCP" section={["Toolkit","Key Resources"]} competency={["Cessation",]} id="1"/>}
                        
                        {(typeFilter.includes('TCTC Webinar') || typeFilter.length==0) && <Box title="TCTC Webinar" type="TCTC Webinar" id="1"/>}

                        {((typeFilter.includes('NTCP') || typeFilter.length==0) && (mainFilter.some(r=> ["Key Resources"].indexOf(r) >= 0) || mainFilter.length==0) && (compFilter.some(r=>["Cessation","Regulation"].indexOf(r) >= 0) || compFilter.length==0)) && <Box title="NTCP Webinar July 2022" type="NTCP" section={["Key Resources"]} competency={["Cessation","Policy"]} id="1"/>}
                        {(typeFilter.includes('Training') || typeFilter.length==0) && <Box title="Training" type="Training" id="1"/>}
                        {(typeFilter.includes('PDF') || typeFilter.length==0) && <Box title="PDF" type="PDF" id="1"/>} */}

                    </div>
                </Col>	
            </Row>						
        </Container>
    )
}