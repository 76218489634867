import React, { useEffect, useState } from "react";

export default function Menu(props){
    const [width,setWidth] = useState("0px");
    const [time,setTime]=useState("0s");
    const [animate,setAnimate]=useState(false);
    const [display,setDisplay]=useState("none");
    const [bgColor,setBgColor]=useState("");
    const style={
        border:"3px blue solid",
        backgroundColor:bgColor,
        width:width,
        padding:"10px",
        boxSizing:"border-box",
        // minHeight:"300px",
        borderRadius:"10px",
        margin:"10px",
        transition:"width "+time,      
    }
    const childStyle={
        display:display
    }
    useEffect(()=>{
        props.time && setTime(props.time)
        props.animate && setAnimate(props.animate)
        // animate? setTimeout(()=>{setWidth(props.width)},500) :setWidth(props.width);
        if(animate){
            setTimeout(()=>{setWidth(props.width)},500)
            setTimeout(()=>setDisplay("block"),2000)
        }
        else{
            setWidth(props.width);
            setDisplay("block")
        }
        if(props.bgColor){
            setBgColor(props.bgColor)
        }else{
            setBgColor("rgba(71, 209, 255, 0.132)");
        }
        // animate ? setTimeout(()=>setDisplay("block"),2000):setDisplay("block");
    },[])

    return(
        <div className="menu" style={style}>
            <div className="childrenContainer" style={childStyle}>
                {props.children}
            </div>            
		</div>
    )


}