import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperclip} from '@fortawesome/free-solid-svg-icons'
export default function ChecklistItem (props){

    const [bgColor,setBgColor]=useState("blue");
    const [show,setShow]=useState("none");
    const [complete,setComplete]=useState(false);

    const innerStyle ={
        backgroundColor:bgColor,
        color:"white"
    } 
    const instructionStyle={
        display:show
    }
    const showInstructionHandler=()=>{
        show=="none" ? setShow("block"):setShow("none");
    }
    const checkHandler=()=>{
        if(complete==true){
            fetch(`https://www.api.tctcollab.com/pmonboarding/incomplete/${props.taskid}/user/${props.userid}`)
        }
        else{
            fetch(`https://www.api.tctcollab.com/pmonboarding/complete/${props.taskid}/user/${props.userid}/pha/${props.phaid}`)
        }
        setComplete(!complete);
        //update task id as checked
    }
    useEffect(()=>{
        props.show ? setShow("block"):setShow("none");
    },[props.show])
    useEffect(()=>{
        switch(props.phase){
            case 1:
                setBgColor("rgb(57,127,12)");
                break;
            case 2:
                setBgColor("rgb(139,20,213)");
                break;
            case 3:
                setBgColor("rgb(255,150,34)");
                break;
        }
        setComplete(props.complete);
        // console.log(props.phaid);
    },[])
    useEffect(()=>{
        switch(props.phase){
            case 1:
                setBgColor("rgb(57,127,12)");
                break;
            case 2:
                setBgColor("rgb(139,20,213)");
                break;
            case 3:
                setBgColor("rgb(255,150,34)");
                break;
        }
    },[props.phase])
    return(
        <div className="checklist-item" style={innerStyle}>
           {props.resourceid!=0&&<a className="ci-attachment m-auto" href={props.resourcelink} target="_BLANK">
            <FontAwesomeIcon icon={faPaperclip} />               
            </a>}
            <div className="ci-inner" >
                <div className="ci-title" onClick={()=>{showInstructionHandler()}} >
                    {props.title}
                </div>
                <div className="ci-instructions" style={instructionStyle} >
                    {props.resourceid!=0&&<div><strong>Toolkit Resource Name: </strong>{props.resourcename}</div>}
                    <div dangerouslySetInnerHTML={{__html:props.children}}>
                    </div>

                    {/* {props.children} */}
                </div>
            </div>
            <div className="ci-check m-auto">
                <Form.Check 
                    type="checkbox"
                    checked={complete}
                    onChange={checkHandler}                    
                 />


            </div>
        </div>
    )
}