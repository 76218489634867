import './App.css';
import React from "react";
import { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from './components/footer';
import Home from './components/home';
import {
  Routes,
  Route,
} from "react-router-dom";
import TCE from './components/tce';
import Pmonboarding from './components/pmonboarding';
import Hero from './components/hero';
import Resource from './components/resource';
import OnboardingList from './components/onboardinglist';
import PasswordReset from './components/passwordreset';
// import RegisterModal from "./components/register";
import AuthContext from './context/authprovider';
import { useContext } from "react";
function App() {
  const {auth}=useContext(AuthContext);
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Hero home={true}/>}/>
        {[1,2,3,4,5,6,7,8].includes(auth.rightid)?<Route path="/tcessentials/*" element={<Hero title="Tobacco Control Essentials"/>} />:<Route path="/tcessentials/*" element={<Hero home={true}/>}/>}
        {[1,2,3,7].includes(auth.rightid)?<Route path="/pmonboarding/*" element={<Hero title="Awardee Onboarding"/>} />:<Route path="/pmonboarding/*" element={<Hero home={true}/>}/>}
        <Route path="/passwordreset/:email/:code" element={<Hero title="Password Reset"/>}/>
        <Route path="/*" element={<Hero home={true}/>}/>
      </Routes>  	
			<div className="body-container" style={{paddingTop:"30px"}}>
          <Routes>
            <Route path="/" element={<Home/>}/>
            {[1,2,3,4,5,6,7,8].includes(auth.rightid)?<Route path="/tcessentials" element={<TCE/>}/>:<Route path="/tcessentials" element={<Home/>}/>}
            {[1,2,3,4,5,6,7,8].includes(auth.rightid)?<Route path="/tcessentials/:id" element={<Resource/>}/>:<Route path="/tcessentials/:id" element={<Home/>}/>}
            {[1,2,3,7].includes(auth.rightid)?<Route path="/pmonboarding" element={<Pmonboarding/>}/>:<Route path="/pmonboarding" element={<Home/>}/>}
            {[1,2,3,7].includes(auth.rightid)?<Route path="/pmonboarding/:id" element={<OnboardingList/>}/>:<Route path="/pmonboarding/:id" element={<Home/>}/>}
            <Route path="/passwordreset/:email/:code" element={<PasswordReset/>}/>
            <Route path="/*" element={<Home/>}/>
          </Routes>
    	</div>
      <Footer/>
		</div>
  );
}

export default App;
