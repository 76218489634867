import React, { useEffect, useState } from "react";
import Menu from "./menu";
import { Container,Row,Col, Form } from "react-bootstrap";
import OnboardingBox from "./onboardingbox";
import MyButton from "./mybutton";
import Steps from './steps';
import Guide from "./guide";
import AuthContext from '../context/authprovider';
import { useContext } from "react";
export default function Pmonboarding (){
    const [fArray,setFArray]=useState([]);
    const [vArray,setVArray]=useState([{}]);
    const [sArray,setSArray] =useState([]);
    const [steps, setSteps]=useState(false);
    const [guide, setGuide]=useState(true);
    const [search,setSearch]=useState("");
    const [showNew,setShowNew]=useState(false);
    const [showOnboarding,setShowOnboarding]=useState(false);
    ///PULL PHA ID FROM CONTEXT
    const {auth}=useContext(AuthContext);
    const [phaid,setPhaid]=useState(auth.userid);

    const handleSearch=(event)=>{
        // if(text.length==1){
        //     setSArray(vArray);
        // }
        // else if (text.lenth==0){
        //     setVArray(sArray);
        // }
        //filter vArray by text and store in vArray dont forget the ...
        setSearch(event.target.value.toLowerCase());
        console.log(search);
    }

    const buttonClick=(b)=>{
        if(b=="s"){
            setSteps(true);
            setGuide(false);
            setShowNew(false);
            setShowOnboarding(false);
        }else if(b=="g"){
            setSteps(false);
            setGuide(true);
            setShowNew(false);
            setShowOnboarding(false);
        }else if(b=="n"){

            ///filter fArray into nArray dont forget ....
            setShowNew(true);
            setSteps(false);
            setGuide(false);
            setShowOnboarding(false);       
        }else if(b=="o"){
            ///filter fArray into nArray dont forget ....
            setShowNew(false);
            setSteps(false);
            setGuide(false);
            setShowOnboarding(true);       
        }else{
            ///fArray into nArray dont forget ....
            setVArray(fArray);
            setShowNew(false);
            setShowOnboarding(false);
            setSteps(false);
            setGuide(false);       
        }
    

    }

    useEffect(()=>{
        //pull all the users grantees and load them into the fArray
        fetch(`https://www.api.tctcollab.com/pmonboarding/pha/${phaid}`)
        .then((response)=>response.json())
        .then((data)=>{setFArray(data);setVArray(data);
            // console.log(data)
        })
    },[])


    return(
            <Container>
                <Row>
                    <Col lg="3">
                        <Menu>
                            {/* <MyButton variant="orange" style={{width:"100%"}} onClick={()=>buttonClick("s")}>Pre-Onboarding Steps</MyButton> */}
                            <MyButton variant="orange" style={{width:"100%"}} onClick={()=>buttonClick("g")} >Onboarding Guide</MyButton>
                        </Menu>
                        <Menu>
                            <Form.Control type="text" placeholder="Search Here..." value={search} onChange={handleSearch}/>
                            <MyButton variant="purple" style={{width:"100%"}} onClick={()=>buttonClick()}>View All Staff</MyButton>
                            <MyButton variant="purple" style={{width:"100%"}} onClick={()=>buttonClick("n")}>View New Staff</MyButton>
                            <MyButton variant="purple" style={{width:"100%"}} onClick={()=>buttonClick("o")}>View Active Onboarding</MyButton>
                        </Menu>
                        {/* <Menu>
                             <MyButton variant="green" style={{width:"100%"}}>Temporarily Transfer Grantees</MyButton> 
                        </Menu> */}
                    </Col>
                    <Col lg="9">
                        {steps? <Steps /> : guide ? <Guide /> :

                            <div className="boxContainer" style={{display:"flex", flexWrap:"wrap",justifyContent:"space-around"}}>
                                {
                                    vArray.map((person)=>{
                                        let percentage=person.percent
                                        person=person.info
                                        let pname=person.fname+" "+person.lname
                                        return((search.length==0 || person.descr.toLowerCase().includes(search) || pname.toLowerCase().includes(search)) && (showNew==false || !(person.first_date_viewed=="0000-00-00")) && (!showOnboarding || person.isonboarding==1) && <OnboardingBox percentage={percentage} phaid={phaid} onboarding={person.isonboarding} name ={person.fname+" "+person.lname} org={person.descr} new={!(person.first_date_viewed=="0000-00-00")} id={person.userid}/>)
                                    })
                                }
                                {/* <OnboardingBox percentage="20" name ="John Doe" org="TCTC" new={true} id="1"/>
                                <OnboardingBox percentage="80" name ="John Doe" org="TCTC"/>
                                <OnboardingBox percentage="19" name ="John Doe" org="TCTC"/>
                                <OnboardingBox percentage="80" name ="John Doe" org="TCTC"/>
                                <OnboardingBox percentage="100" name ="John Doe" org="TCTC"/>
                                <OnboardingBox percentage="50" name ="John Doe" org="TCTC"  new={true}/>
                                <OnboardingBox percentage="100" name ="John Doe" org="TCTC"/>
                                <OnboardingBox percentage="100" name ="John Doe" org="TCTC"/>
                                <OnboardingBox percentage="100" name ="John Doe" org="TCTC"/>
                                <OnboardingBox percentage="100" name ="John Doe" org="TCTC"/>
                                <OnboardingBox percentage="100" name ="John Doe" org="TCTC"/> */}
                            </div>

                        }
                    </Col>
                </Row>
            </Container>
            

    )
}