import {
    Container,
      Image,
      Navbar,
      Nav,
  } from "react-bootstrap";
import logo from "../img/logo.png";
import { Link } from "react-router-dom";
import {LinkContainer} from 'react-router-bootstrap'
import LoginModal from "./loginmodal";
import { useState } from "react";
import AuthContext from '../context/authprovider';
import { useContext,useEffect } from "react";
export default function NavbarComp (){
    const [login,setLogin]=useState(false);
    const {auth,setAuth}=useContext(AuthContext);

    const loginClick=()=>{
        setLogin(!login);
        // console.log("click");
    }
    const logoutClick=()=>{
        setAuth({});
        localStorage.removeItem("user");
        // console.log(JSON.stringify(auth))
        // console.log("click");
    }
    useEffect(()=>{
        let saved = localStorage.getItem("user");
        if(saved && JSON.stringify(auth)==="{}"){
            setAuth(JSON.parse(saved));
        }
    },[])
    return(
    <div>
        <Navbar expand="sm" scrolling light >
            <Container fluid>
                <Navbar.Brand as={Link} to="/"><Image src={logo} style={{width:"45px"}} /></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav" dark>
                    <Nav className="ms-auto">
                        {/* <Nav.Link as={Link} to="/">Home</Nav.Link>
                        <Nav.Link  as={Link} to="/tcessentials">Tobacco Control Essentials</Nav.Link>
                        <Nav.Link  as={Link} to="/pmonboarding">Grantee Onboarding</Nav.Link>
                        <Nav.Link  as={Link} to = "/contactus">Contact Us</Nav.Link> */}                    

                        <LinkContainer to="/">
                            <Nav.Link>Home</Nav.Link>
                        </LinkContainer>
                    {JSON.stringify(auth)!=="{}"&&[1,2,3,4,5,6,7,8].includes(auth.rightid)&&
                        <LinkContainer to="/tcessentials">
                            <Nav.Link>Tobacco Control Essentials</Nav.Link>
                        </LinkContainer>}
                    {JSON.stringify(auth)!=="{}"&&[1,2,3,7].includes(auth.rightid)&&    
                        <LinkContainer to="/pmonboarding">
                            <Nav.Link>Awardee Onboarding</Nav.Link>
                        </LinkContainer>}
                        {/* <LinkContainer to = "/contactus">
                            <Nav.Link>Contact Us</Nav.Link>
                        </LinkContainer> */}
                        {JSON.stringify(auth)==="{}"?<div onClick={()=>loginClick()}>
                            <Nav.Link className="login-button">Login</Nav.Link>
                        </div>:
                        <div onClick={()=>logoutClick()}>
                            <Nav.Link className="logout-button">Logout</Nav.Link>
                        </div>}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        <LoginModal clicked={login}  click={loginClick}/>
    </div>
    )
}